import React, {useContext} from 'react';
import ConfirmModal from '../Common/Modal/ConfirmModal';
import Notification from '../Common/Notification/Notification';
import {useHistory} from 'react-router-dom';
import PATHS from '../../../permissions/AppPaths';
import ModalProvider from '../../providers/modalProvider';
import {useTranslation} from 'react-i18next';
import Prediction from '../../services/http/Prediction';

interface IModalDeletePrediction {
    data: any,
}
const ModalDeletePrediction = (props: IModalDeletePrediction) => {
    const { data } = props;
    const history = useHistory();
    const [, setShowModal] = useContext(ModalProvider);
    const {t} = useTranslation();
    const deletePrediction = async () => {
        try {
            const predictionService = new Prediction();
            await predictionService.delete(data);
            Notification.display('success', t('predictionDeletedSuccessfully'));
        }
        catch (err) {
            Notification.displayException(err);
        }
        finally {
            setShowModal(false);
            history.push(PATHS.home);
        }
    };
    return (
        <ConfirmModal title={'askDeletePrediction'} onConfirm={deletePrediction} confirmLabel={'confirmDeletePrediction'} cancelLabel={'cancelDeletePrediction'} />
    );
};
export default ModalDeletePrediction;

import TCompany from './TCompany';

type TUser = {
  id: string;
  email: string;
  phone: string;
  password: string;
  name: string;
  web?: string;
  avatar?: string;
  company?: TCompany;
  admin?: boolean;
  language?: string;
  socialAccount?: boolean;
  verified?: boolean;
  roles: Array<EUserRoles>;
  firstTime?: boolean;
  skills?: TSkill[];
};

export enum EUserRoles {
  // eslint-disable-next-line no-unused-vars
  ROLE_COMPANY = 'ROLE_COMPANY',
// eslint-disable-next-line no-unused-vars
  ROLE_ADMIN = 'ROLE_ADMIN',
  // eslint-disable-next-line no-unused-vars
  ROLE_PREDICTION = 'ROLE_PREDICTION',
  // eslint-disable-next-line no-unused-vars
  ROLE_PRO = 'ROLE_PRO',
  // eslint-disable-next-line no-unused-vars
  ROLE_LICENSING = 'ROLE_LICENSING',
}
export type TSkill = {
  name: string,
  value: string|number
};
export default TUser;

import React, {useContext} from 'react';
import ConfirmModal from '../Common/Modal/ConfirmModal';
import Campaign from '../../services/http/Campaign';
import Notification from '../Common/Notification/Notification';
import {useHistory} from 'react-router-dom';
import PATHS from '../../../permissions/AppPaths';
import ModalProvider from '../../providers/modalProvider';
import {useTranslation} from 'react-i18next';

interface IModalDeleteCampaign {
    data: any,
}
const ModalDeleteCampaign = (props: IModalDeleteCampaign) => {
    const { data } = props;
    const history = useHistory();
    const [, setShowModal] = useContext(ModalProvider);
    const {t} = useTranslation();
    const deleteCampaign = async () => {
        try {
            const campaignService = new Campaign();
            await campaignService.deleteCampaign(data);
            Notification.display('success', t('campaignDeletedSuccessfully'));
        }
        catch (err) {
            Notification.displayException(err);
        }
        finally {
            setShowModal(false);
            history.push(PATHS.home);
        }
    };
    return (
        <ConfirmModal title={'askDeleteCampaign'} onConfirm={deleteCampaign} confirmLabel={'confirmDeleteCampaign'} cancelLabel={'cancelDeleteCampaign'} />
    );
};
export default ModalDeleteCampaign;

import fp from 'lodash/fp';


export function objToQuery(filter: Record<string, unknown> | any): string {
    return fp.pipe(
        fp.toPairs,
        fp.filter(([key, value]) => {
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            else {
                return value !== undefined && value !== false;
            }
        }),
        fp.map(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.some((item) => typeof item === 'object')) {
                    const serializedValues = value.map((item) => JSON.stringify(item));
                    return `${key}=${JSON.stringify(serializedValues)}`;
                }
                else {
                    return `${key}=${value.join(',')}`;
                }
            }
            else {
                return `${key}=${value}`;
            }
        }),
        fp.filter((str) => str !== ''),
        fp.join('&'),
        (str) => `?${str}`
    )(filter);
}

export function queryToObj(search: string) {
    if (!search || search === '') return {};

    return fp.pipe(
        fp.split('?'),
        fp.tail,
        fp.split('&'),
        fp.map((param: string) => param.split('=')),
        fp.map((item: any[]) => decodeURIComponent(item[1])),
        fp.fromPairs
    )(search);
}

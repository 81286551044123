import React, {
    useState,
    useEffect,
    useRef,
    useContext,
    createContext,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { StatusCodes } from 'http-status-codes';

import { SocketConnectionContext } from 'metrics/providers/socketConnectionProvider';
import Notification from 'metrics/components/Common/Notification/Notification';
import { ESocketEventsEmit, ESocketEventsOn } from 'metrics/types/ESocketEvents';

interface IUseFileUpload {
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
    emitCompletedState: () => void;
    emitAbortState: () => void;
    file: File | null;
    processPercentageNumber: number | null;
    url: string | null;
    getUrlBySocket: () => Promise<string | null>;
    error: IWsException | null;
}

interface IWsException {
    statusCode: number;
    message: string;
    error_type: string;
}

const CHUNK_SIZE_5MB = 5 * 1024 * 1024;

export const FileUploaderContext = createContext<IUseFileUpload>({
    handleFileChange: async () => {},
    emitCompletedState: () => {},
    emitAbortState: () => {},
    file: null,
    processPercentageNumber: null,
    url: null,
    getUrlBySocket: async () => '',
    error: null,
});

export const FileUploaderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { socket } = useContext(SocketConnectionContext);
    const [file, setFile] = useState<File | null>(null);
    const extension = useRef<string | null>(null);
    const dataParts = useRef<Array<ArrayBuffer>>([]);
    const initialIndexBuffer = 1;
    const indexDataBuffer = useRef<number>(initialIndexBuffer);
    const process = {
        INIT: 0,
        UPLOAD: 1,
        COMPLETED: 2,
        ABORT: 3,
    };
    const [fileId] = useState(uuidv4());
    const type = 'media';
    const [url, setUrl] = useState<string | null>(null);
    const [processPercentageNumber, setProcessPercentageNumber] = useState<number | null>(null);
    const [error, setError] = useState<null | IWsException>(null);
    const stateProcess = useRef<number | null>(null);


    useEffect(() => {
        if (!socket) return;
        socket.on(ESocketEventsOn.MULTIPART_UPLOADER_INIZIALIZED, () => {
            console.log('Init upload multipart');
            uploadDataParts();
        });

        socket.on(ESocketEventsOn.MULTIPART_UPLOADER_UPLOAD_PART, (data) => {
            setProcessPercentageNumber(data.percentCompleted);
        });

        socket.on(ESocketEventsOn.ERROR, (err) => {
            setError(err);
        });
    }, [socket]);

    useEffect(() => {
        if (error) {
            Notification.displayException(error as any);
            setError(null);
            if (error.statusCode !== StatusCodes.UNAUTHORIZED && stateProcess.current !== null) {
                emitAbortState();
            }
        }
    }, [error]);

    const uploadDataParts = () => {
        if (dataParts.current.length === 0 || error) return;
        dataParts.current.forEach((_, index) => {
            indexDataBuffer.current = index + 1;
            emitUploadState();
        });
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0] || null;
        setFile(selectedFile);
        if (!selectedFile) return;
        extension.current = `.${selectedFile.type.split('/')[1]}`;
        const dataPartsBuffer = await getDataPartsInBufferFile(selectedFile);
        dataParts.current = dataPartsBuffer;
        emitInitState(dataPartsBuffer.length);
    };

    const emitInitState = (totalParts: number) => {
        if (!socket) return;
        socket.emit(ESocketEventsEmit.MULTIPART_UPLOADER, {
            state: process.INIT,
            id: fileId,
            type: type,
            extension: extension.current,
            totalParts: totalParts,
        });
        setProcessPercentageNumber(0);
        stateProcess.current = process.INIT;
    };

    const getDataPartsInBufferFile = (file: File) => {
        const dataParts = [];
        const chunkSize = CHUNK_SIZE_5MB;
        const fileSize = file?.size || 0;
        const totalChunks = Math.ceil(fileSize / chunkSize);
        for (let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(fileSize, start + chunkSize);
            const chunk = file?.slice(start, end);

            const bufferPromise = new Promise<ArrayBuffer>(
                (resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result as ArrayBuffer);
                    reader.onerror = () => reject(reader.error);
                    reader.readAsArrayBuffer(chunk);
                }
            );
            dataParts.push(bufferPromise);
        }
        return Promise.all(dataParts);
    };

    const emitUploadState = () => {
        if (!socket) return;
        socket.emit(ESocketEventsEmit.MULTIPART_UPLOADER, {
            state: process.UPLOAD,
            id: fileId,
            dataIndex: indexDataBuffer.current,
            data: dataParts.current[indexDataBuffer.current - 1],
        });
        stateProcess.current = process.UPLOAD;
    };

    const emitCompletedState = async () => {
        if (!socket) return;
        socket.emit(ESocketEventsEmit.MULTIPART_UPLOADER, {
            state: process.COMPLETED,
            id: fileId,
        });
        try {
            await getUrlBySocket();
        }
        catch (error) {
            console.error(error);
        }
        finally {
            stateProcess.current = null;
            indexDataBuffer.current = initialIndexBuffer;
        }
    };

    const emitAbortState = () => {
        if (!socket) return;
        socket.emit(ESocketEventsEmit.MULTIPART_UPLOADER, {
            state: process.ABORT,
            id: fileId,
        });
        setProcessPercentageNumber(null);
        stateProcess.current = null;
        indexDataBuffer.current = initialIndexBuffer;
    };

    const getUrlBySocket = async (): Promise<string | null> => {
        if (!socket) return Promise.resolve(null);
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(new Error('Timeout: URL not received'));
            }, 6000);
            socket.on(ESocketEventsOn.MULTIPART_UPLOADER_COMPLETED, (data) => {
                clearTimeout(timeout);
                setUrl(data.url);
                setProcessPercentageNumber(null);
                resolve(data.url);
            });
        });
    };
    const value = {
        handleFileChange,
        emitCompletedState,
        emitAbortState,
        file,
        processPercentageNumber: processPercentageNumber,
        url,
        getUrlBySocket,
        error,
    };

    return (
        <FileUploaderContext.Provider value={value}>
            {children}
        </FileUploaderContext.Provider>
    );
};

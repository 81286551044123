import React, {useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import i18n from 'i18next';
import Routes from './Routes';
import HttpStorageI18n from './metrics/services/storage/Language';
import './DefaultStyles';
import {GoogleOAuthProvider} from '@react-oauth/google';
import WizardCreateCampaignStepProvider from 'metrics/providers/WizardCreateCampaignStepProvider';
import {
    WizardStorage,
} from './metrics/services/storage/Wizard';
import WizardCreatePredictionStepProvider from 'metrics/providers/WizardCreatePredictionStepProvider';
import CustomClientProvider from './metrics/providers/CustomClientProvider';
import { capitalize, getSubDomain } from './metrics/utils/utils';
import {customLocaleSubdomains} from './metrics/constants';
import { SocketConnectionProvider } from 'metrics/providers/socketConnectionProvider';

const App = () => {
    const basename: string = '/';
    const [wizardStepCreateCampaign, setWizardStepCreateCampaign] = useState<number>(0);
    const [wizardStepCreatePrediction, setWizardStepCreatePrediction] = useState<number>(0);
    const [customClient, setCustomClient] = useState(false);
    const subdomain = getSubDomain(window.location.hostname);

    useEffect(() => {
        if (customLocaleSubdomains.includes(subdomain)) {
            i18n.changeLanguage(subdomain);
            HttpStorageI18n.saveLanguage(subdomain);
        }
        else if (!HttpStorageI18n.getItem('language')) {
            let userLang = 'en';
            const navigatorLanguage = navigator.language.slice(0, 2);
            const languagesList = [
                { acronym: 'en' },
                { acronym: 'es' },
                { acronym: 'licencing-pre' },
                { acronym: 'wistron' },
            ];
            const objFindLang = languagesList.find(
                (item: any) => item.acronym === navigatorLanguage
            );
            if (objFindLang) userLang = objFindLang.acronym;

            HttpStorageI18n.saveLanguage(userLang);
            i18n.changeLanguage(userLang);
        }

        const wizard = new WizardStorage();
        setWizardStepCreateCampaign(wizard.wizardStepCreateCampaign());
        setWizardStepCreatePrediction(wizard.wizardStepCreatePrediction());

        if (subdomain !== 'metrics' && subdomain !== 'metricspre' && subdomain !== 'localhost') {
            setCustomClient(true);
        }
    }, [i18n]);

    const changeFavicon = (src: string) => {
        const link = document.createElement('link');
        const oldLink = document.querySelector('link[rel=\'shortcut icon\']');
        link.rel = 'icon';
        link.href = src;
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
    };

    if (customClient) {
        import(`./styles/custom/${subdomain}.css`)
            .catch((error) => {
                console.error(`Failed to import CSS file for subdomain '${subdomain}':`, error);
            });
        document.title = `${capitalize(subdomain)}`;
        changeFavicon('/custom/favicon.ico');
    }

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string} onScriptLoadError={() => {}} onScriptLoadSuccess={() => {}}>
            <CustomClientProvider.Provider value={customClient}>
                <WizardCreateCampaignStepProvider.Provider value={[wizardStepCreateCampaign, setWizardStepCreateCampaign]}>
                    <WizardCreatePredictionStepProvider.Provider value={[wizardStepCreatePrediction, setWizardStepCreatePrediction]}>
                        <SocketConnectionProvider>
                            <BrowserRouter basename={basename}>
                                <Routes />
                            </BrowserRouter>
                        </SocketConnectionProvider>
                    </WizardCreatePredictionStepProvider.Provider>
                </WizardCreateCampaignStepProvider.Provider>
            </CustomClientProvider.Provider>
        </GoogleOAuthProvider>
    );
};

export default App;

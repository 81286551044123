import TCompany from 'metrics/types/TCompany';
import Http from './Http';
import {handleError} from './utils';
import {v4 as uuidv4} from 'uuid';
import {IValuesSelect} from 'metrics/components/Common/Select/Select';

export default class Company extends Http {
    constructor() {
        super('');
    }

    private transformCompanyData(data: TCompany) {
        const dataMap = {
            'id': data.id || uuidv4(),
            'name': data.name,
            'tax_id_number': data.taxIdNumber,
            'sector': data.sector,
            'billing_address_line1': data.billingAddress.line1,
            'billing_address_line2': data.billingAddress.line2,
            'billing_address_city': data.billingAddress.city,
            'billing_address_state': data.billingAddress.state,
            'billing_address_postal_code': data.billingAddress.postalCode,
            'billing_address_country_code': data.billingAddress.countryCode,
            'avatar_path': data.avatar || null,
            'phone': data.phone,
        };
        return dataMap;
    }

    async create(data: TCompany): Promise<any> {
        try {
            let result = {};
            const dataMap = this.transformCompanyData(data);
            result = await this.post('/companies', dataMap, this.requestConfig);
            return result;
        }
        catch (err) {
            handleError(err);
        }
    }

    async update(data: TCompany): Promise<any> {
        try {
            const dataMap = this.transformCompanyData(data);
            const req: any = dataMap;
            delete req['id'];
            return await this.put(`/companies/${data.id}`, req, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }

    async sectors() {
        let result = [];

        try {
            result = await this.get('/sectors', this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }

        return result;
    }

    async sectorsForSelect(): Promise<IValuesSelect[]> {
        const ret: IValuesSelect[] = [];
        const res = await this.sectors();
        if (res) {
            res.data.forEach((type: any) => {
                ret.push({label: type, value: type});
            });
        }
        return ret;
    }

    async invites(companyId: string, emails: string[]) {
        if (companyId) {
            try {
                for (let i = 0; i < emails.length; i++) {
                    const data = {
                        'id': uuidv4(),
                        'email': emails[i],
                    };
                    await this.post(`/company/${companyId}/invites`,
                        data, this.requestConfig);
                }
            }
            catch (err) {
                handleError(err);
            }
        }
    }

    async getInvites(companyId: string) {
        try {
            const ret = await this.get(`/company/${companyId}/invites`);
            return ret.data;
        }
        catch (err) {
            handleError(err);
        }
    }

    async cancelSubscription(
        subscriptionId: string,
    ) {
        try {
            await this.delete(`/subscriptions/${subscriptionId}`);
        }
        catch (e) {
            handleError(e);
        }
    }

    async setSubscription(
        subscriptionId: string,
        companyId: string,
        planId: string
    ) {
        try {
            const data = {
                'id': subscriptionId,
                'package': planId,
            };
            await this.post(
                `/company/${companyId}/subscriptions`,
                data,
                this.requestConfig
            );
        }
        catch (err) {
            handleError(err);
        }
    }

    async getSubscriptionPaymentUrl(subscriptionId: string) {
        try {
            const ret = await this.get(`/subscriptions/${subscriptionId}?paymentUrl=true`);
            return ret.data.paymentUrl;
        }
        catch (err) {
            handleError(err);
        }
    }

    async addProductsPackage(
        productsPackageId: string,
        companyId: string,
        packageId: string
    ) {
        try {
            const data = {
                'id': productsPackageId,
                'package': packageId,
            };
            await this.post(
                `/company/${companyId}/products-packages`,
                data,
                this.requestConfig
            );
        }
        catch (err) {
            handleError(err);
        }
    }

    async getAllCompanies() {
        try {
            return await this.get('/companies');
        }
        catch (err) {
            handleError(err);
        }
    }

    async getInvoices(companyId: string) {
        try {
            const res = await this.get(`/company/${companyId}/invoices`);
            return res.data;
        }
        catch (e) {
            handleError(e);
        }
    }

    async requestCorporatePlan(companyId: string) {
        try {
            await this.get(`/company/${companyId}/corporate-subscriptions`);
        }
        catch (e) {
            handleError(e);
        }
    }

    async getProductsPackagePaymentUrl(packageId: string) {
        try {
            const ret = await this.get(`/products-packages/${packageId}?paymentUrl=true`);
            return ret.data.paymentUrl;
        }
        catch (err) {
            handleError(err);
        }
    }
}

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {getSubDomain} from './utils/utils';

import translationEn from './i18n/en/translations.json';
import translationEs from './i18n/es/translations.json';
import translationWistron from './i18n/wistron/translations.json';
import translationLicencingPre from './i18n/licensing-pre/translations.json';

const subdomain = getSubDomain(window.location.hostname);

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            'en': { translation: translationEn },
            'es': { translation: translationEs },
            'wistron': { translation: translationWistron },
            'licencing-pre': { translation: translationLicencingPre},
        },
        lng: subdomain || navigator.language.slice(0, 2) || 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        ns: ['translations', 'countries'],
    });

export default i18n;

export enum EPackageName {
    // eslint-disable-next-line no-unused-vars
    free = 'Free',
    // eslint-disable-next-line no-unused-vars
    basic = 'Basic',
    // eslint-disable-next-line no-unused-vars
    pro = 'Pro',
    // eslint-disable-next-line no-unused-vars
    trend = 'Trend',
    // eslint-disable-next-line no-unused-vars
    ab = 'A/B',
    // eslint-disable-next-line no-unused-vars
    deep = 'Deep',
    // eslint-disable-next-line no-unused-vars
    licensing = 'Licensing'
}

type TPackage = {
    id: string,
    price: number,
    name: EPackageName,
    type: 'renewal_monthly' | 'renewal_yearly' | 'one_time_reactions' | 'one_time_predictions' | 'one_time_campaigns',
    currency: string,
    reactionsPerMonth: number,
    predictionsPerMonth: number,
    campaignsPerMonth: number,
}

export default TPackage;

import React, {Fragment, useContext, useState} from 'react';
import ModalProvider, {ModalProviderData} from '../../providers/modalProvider';
import {useTranslation} from 'react-i18next';
import {Dialog, Transition} from '@headlessui/react';
import Button from '../Common/Button/Button';
import {useHistory} from 'react-router-dom';
import PATHS from '../../../permissions/AppPaths';
import {APP_MODALS} from '../../constants';
import UserProvider from '../../providers/userProvider';
import {EUserRoles} from '../../types/TUser';
import {WizardStorage} from '../../services/storage/Wizard';
import WizardCreateCampaignStepProvider from '../../providers/WizardCreateCampaignStepProvider';
import WizardCreatePredictionStepProvider from '../../providers/WizardCreatePredictionStepProvider';

const ModalTutorials = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [, setShowModal] = useContext(ModalProvider);
    const {t} = useTranslation();
    const history = useHistory();
    const [user] = useContext(UserProvider);
    const wizard = new WizardStorage();
    const [, setWizardStepCreateCampaign] = useContext(WizardCreateCampaignStepProvider);
    const [, setWizardStepCreatePrediction] = useContext(WizardCreatePredictionStepProvider);

    const handleRedirectCreateCampaign = () => {
        refreshWizard();
        closeModal();
        history.push(PATHS.newCampaign + '?wizard=true');
    };

    const handleRedirectCreatePrediction = () => {
        refreshWizard();
        closeModal();
        setTimeout(() => {
            const modalData : ModalProviderData = {
                modalName: APP_MODALS.modalNewPrediction,
                data: {
                    wizardMode: true,
                },
            };
            setShowModal(modalData);
        }, 500);
    };

    const closeModal = () => {
        setShowModal(false);
        setIsOpen(false);
    };

    const refreshWizard = () => {
        wizard.refreshAll();
        setWizardStepCreateCampaign(0);
        setWizardStepCreatePrediction(0);
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto bg-ultra-dark-blue bg-opacity-80"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
            &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="inline-block w-full max-w-xl py-6 px-10 mb-40 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                        >
                            <Dialog.Title
                                as="h3"
                                className="text-4xl font-semibold text-primary pb-3 text-center"
                            >
                                {t('modalTutorialsTitle')}
                            </Dialog.Title>
                            <Dialog.Description
                                as='p'
                                className='text-lg font-semibold'
                            >
                                {t('modalTutorialsDescription')}
                            </Dialog.Description>
                            <div className='flex flex-row justify-around mt-5 space-x-2'>
                                <Button
                                    type='primary'
                                    className='rounded-2xl'
                                    onClick={handleRedirectCreateCampaign}
                                >
                                    {t('modalTutorialsCreateCampaign')}
                                </Button>
                                {
                                    user?.roles.includes(EUserRoles.ROLE_PREDICTION) &&
                                    <Button
                                        disabled={false}
                                        type='secondary'
                                        className='rounded-2xl'
                                        onClick={handleRedirectCreatePrediction}
                                    >
                                        {t('modalTutorialsCreatePrediction')}
                                    </Button>
                                }
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ModalTutorials;

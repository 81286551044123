import Axios from 'axios';
import moment from 'moment';
import TCampaign, {
    TCampaignCreateDataV1,
    TCampaignCreateDataV2Custom,
    TCampaignCreateDataV2NotCustom,
    TCampaignCreateRequestV1,
    TCampaignCreateRequestV2Custom,
    TCampaignCreateRequestV2NotCustom,
} from 'metrics/types/TCampaign';
import {IReportFilters} from 'metrics/utils/ChartUtils';
import {objToQuery} from 'metrics/utils/Router';
import {uuidv4} from 'metrics/utils/utils';
import Http from './Http';
import {handleError} from './utils';
import {TContentType} from '../../types/TContentType';
import {DEFAULT_CONTENT_TYPE} from '../../constants';

export interface IReport {
    key: string,
    value: string | number
}


export default class Campaign extends Http {
    constructor() {
        super('');
    }

    async userCampaignList(): Promise<TCampaign[]> {
        let result = {data: []};

        try {
            result = await Axios.get(
                `/me/campaigns?pending=true`,
                this.requestConfig
            );
        }
        catch (err) {
            handleError(err);
        }
        return result.data;
    }

    async list(companyId: string): Promise<TCampaign[]> {
        let result = {data: []};

        try {
            result = await Axios.get(
                `company/${companyId}/campaigns`,
                this.requestConfig
            );
        }
        catch (err) {
            handleError(err);
        }
        return result.data;
    }

    async getCampaigObjectives() {
        let result = {data: []};
        try {
            result = await Axios.get(`/campaign-objectives`, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
        return result.data;
    }

    private static parseCreateDataV1(data: TCampaignCreateDataV1): TCampaignCreateRequestV1 {
        // @ts-ignore
        const ret: TCampaignCreateRequestV1 = {
            id: uuidv4(),
            code: data.code,
            title: data.title,
            configuration_private: true,
            configuration_gender: null,
            configuration_age_from: null,
            configuration_age_to: null,
            configuration_start_date: moment(data.fromDate).format('YYYY-MM-DD HH:mm:ss'),
            configuration_stop_date: moment(data.toDate).format('YYYY-MM-DD HH:mm:ss'),
            configuration_total_reactions: Number(data.countReactions),
            objectives: data.objectives,
            thanks: data.thanks,
            offer_link: data.offerLink,
            thumb_path: 'undefined',
            media_path: data.video,
            wallpaper_path: data.wallpaper,
            sector: data.sector,
            description_long: data.descriptionLong,
            description_content: 'Don’t act.',
            description_prefix: 'React',
            description_suffix: 'to this content',
            question: data.question,
        };


        for (const key in ret) { // @ts-ignore
            if (ret[key] === null || ret[key] === '' || ret[key] === 'null') {
                // @ts-ignore
                delete ret[key];
            }
        }
        return ret;
    }

    private static parseCreateDataV2Custom(data: TCampaignCreateDataV2Custom): TCampaignCreateRequestV2Custom {
        // @ts-ignore
        const ret: TCampaignCreateRequestV2Custom = {
            id: data.id,
            code: data.code,
            title: data.title,
            configuration_private: true,
            configuration_gender: null,
            configuration_age_from: null,
            configuration_age_to: null,
            configuration_start_date: moment(data.fromDate).format('YYYY-MM-DD HH:mm:ss'),
            configuration_stop_date: moment(data.toDate).format('YYYY-MM-DD HH:mm:ss'),
            configuration_total_reactions: Number(data.countReactions),
            configuration_color: data.color,
            thanks: data.thanks,
            offer_link: data.offerLink,
            thumb_path: 'undefined',
            media_path: data.video,
            wallpaper_path: data.wallpaper,
            sector: data.sector,
            description_long: data.descriptionLong,
            description_content: 'Don’t act.',
            description_prefix: 'React',
            description_suffix: 'to this content',
            question: data.question,
        };


        for (const key in ret) { // @ts-ignore
            if (ret[key] === null || ret[key] === '' || ret[key] === 'null') {
                // @ts-ignore
                delete ret[key];
            }
        }
        return ret;
    }

    private static parseCreateDataV2NotCustom(data: TCampaignCreateDataV2NotCustom): TCampaignCreateRequestV2NotCustom {
        // @ts-ignore
        const ret: TCampaignCreateRequestV2NotCustom = {
            id: data.id,
            code: data.code,
            title: data.title,
            configuration_private: true,
            configuration_gender: null,
            configuration_age_from: null,
            configuration_age_to: null,
            configuration_start_date: moment(data.fromDate).format('YYYY-MM-DD HH:mm:ss'),
            configuration_stop_date: moment(data.toDate).format('YYYY-MM-DD HH:mm:ss'),
            configuration_total_reactions: Number(data.countReactions),
            thanks: data.thanks,
            offer_link: data.offerLink,
            thumb_path: 'undefined',
            media_path: data.video,
            wallpaper_path: data.wallpaper,
            sector: data.sector,
            description_long: data.descriptionLong,
            description_content: 'Don’t act.',
            description_prefix: 'React',
            description_suffix: 'to this content',
            question: data.question,
        };


        for (const key in ret) { // @ts-ignore
            if (ret[key] === null || ret[key] === '' || ret[key] === 'null') {
                // @ts-ignore
                delete ret[key];
            }
        }
        return ret;
    }

    private async create(companyId: string, res: TCampaignCreateRequestV1| TCampaignCreateRequestV2Custom| TCampaignCreateDataV2NotCustom) {
        let result = {data: []};
        try {
            result = await this.post(`/company/${companyId}/campaigns`,
                res,
                this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }

        return result;
    }

    async createV1(companyId: string, data: TCampaignCreateDataV1) {
        const res = Campaign.parseCreateDataV1(data);
        const result = await this.create(companyId, res);
        return result.data;
    }

    async createV2Custom(companyId: string, data: TCampaignCreateDataV2Custom) {
        const res = Campaign.parseCreateDataV2Custom(data);
        const result = await this.create(companyId, res);
        return result.data;
    }

    async createV2NotCustom(companyId: string, data: TCampaignCreateDataV2NotCustom) {
        const res = Campaign.parseCreateDataV2NotCustom(data);
        const result = await this.create(companyId, res);
        return result.data;
    }

    private async update(campaignId: string, res: TCampaignCreateRequestV1| TCampaignCreateRequestV2Custom| TCampaignCreateDataV2NotCustom | any) {
        let result = {data: []};
        const toDelete = ['media_path', 'wallpaper_path'];
        toDelete.forEach((key: string) => {
            if (res[key] && (res[key].startsWith('http') || res[key] == '')) {
                delete res[key];
            }
        });
        delete res.id;
        res['configuration_enabled'] = true; // Rq para que no desactive la campaña;
        try {
            result = await this.put(`/campaigns/${campaignId}`,
                res,
                this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
        return result;
    }

    async updateV1(campaignId: string, data: TCampaignCreateDataV1) {
        const res = Campaign.parseCreateDataV1(data);
        const result = await this.update(campaignId, res);
        return result.data;
    }

    async updateV2Custom(campaignId: string, data: TCampaignCreateDataV2Custom) {
        const res = Campaign.parseCreateDataV2Custom(data);
        const result = await this.update(campaignId, res);
        return result.data;
    }

    async updateV2NotCustom(campaignId: string, data: TCampaignCreateDataV2NotCustom) {
        const res = Campaign.parseCreateDataV2NotCustom(data);
        const result = await this.update(campaignId, res);
        return result.data;
    }

    async get(campaignId: string): Promise<TCampaign | undefined> {
        try {
            const ret = await Axios.get(`/campaigns/${campaignId}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async objectivesScore(campaignId: string, all: boolean = false, algorithmVersion: number = 1): Promise<string[] | undefined> {
        try {
            this.requestConfig.headers = { Version: algorithmVersion };
            const ret = await Axios.get(
                `/campaign/${campaignId}/score-objectives${all ? '?all=true' : ''}`,
                this.requestConfig,
            );
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async videoMetricsByObjective(campaignId: string, objective: string, params?: IReportFilters, algorithmVersion: number = 1) {
        try {
            let query = '';
            if (params) {
                query = objToQuery(params);
            }
            this.requestConfig.headers = { Version: algorithmVersion };
            const ret = await Axios.get(`/campaign/${campaignId}/objective/${objective}/reports${query}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async genderReports(campaignId: string, params?: IReportFilters): Promise<IReport[] | undefined> {
        try {
            let query = '';
            if (params) {
                query = objToQuery(params);
            }
            const ret = await Axios.get(`/campaign/${campaignId}/gender-reports${query}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async ageReports(campaignId: string, params?: IReportFilters): Promise<IReport[] | undefined> {
        try {
            let query = '';
            if (params) {
                query = objToQuery(params);
            }
            const ret = await Axios.get(`/campaign/${campaignId}/age-reports${query}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async physicalAttributesReports(campaignId: string, params?: IReportFilters): Promise<IReport[] | undefined> {
        try {
            let query = '';
            if (params) {
                query = objToQuery(params);
            }
            const ret = await Axios.get(`/campaign/${campaignId}/physical-attributes-reports${query}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async cityReports(campaignId: string, params?: IReportFilters): Promise<IReport[] | undefined> {
        try {
            let query = '';
            if (params) {
                query = objToQuery(params);
            }
            const ret = await Axios.get(`/campaign/${campaignId}/address-city-reports${query}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async nationalityReports(campaignId: string, params?: IReportFilters): Promise<IReport[] | undefined> {
        let query = '';
        if (params) {
            query = objToQuery(params);
        }
        try {
            const ret = await Axios.get(`/campaign/${campaignId}/nationality-reports${query}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async pauseCampaign(campaignId: string): Promise<any> {
        try {
            // const campaign = await this.get(campaignId);
            const req: any = {};
            req['configuration_enabled'] = false;
            const ret = await Axios.put(`/campaigns/${campaignId}`, req, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
        return;
    }

    async resumeCampaign(campaignId: string) {
        try {
            // const campaign = await this.get(campaignId);
            const req: any = {};
            req['configuration_enabled'] = true;
            const ret = await Axios.put(`/campaigns/${campaignId}`, req, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
        return;
    }

    async getOverviewReport(campaignId: string): Promise<any> {
        this.requestConfig.headers = { Version: 2 };
        try {
            const ret = await Axios.get(`/campaign/${campaignId}/overview`, this.requestConfig);
            return ret.data;
        }
        catch (e) {
            handleError(e);
        }
    }

    async getAnalysisReport(campaignId: string, params?: IReportFilters, contentType?: TContentType): Promise<any> {
        if (contentType === undefined || contentType === null) {
            contentType = DEFAULT_CONTENT_TYPE;
        }
        this.requestConfig.headers = {
            'Version': 2,
            'Accept': contentType,
        };


        let query = '';
        if (params) {
            query = objToQuery(params);
        }
        try {
            const ret = await Axios.get(`/campaign/${campaignId}/analysis-kpis${query}`, this.requestConfig);
            return ret.data;
        }
        catch (e) {
            handleError(e);
        }
    }

    async getAnalysisBasicEmotionsReport(campaignId: string, params?: IReportFilters, contentType?: TContentType): Promise<any> {
        if (contentType === undefined || contentType === null) {
            contentType = DEFAULT_CONTENT_TYPE;
        }
        this.requestConfig.headers = {
            'Version': 2,
            'Accept': contentType,
        };


        let query = '';
        if (params) {
            query = objToQuery(params);
        }
        try {
            const ret = await Axios.get(`/campaign/${campaignId}/analysis-emotions${query}`, this.requestConfig);
            return ret.data;
        }
        catch (e) {
            handleError(e);
        }
    }

    async checkCampaignHasReactions(campaignId: string): Promise<boolean> {
        try {
            const ret = await Axios.head(`/campaign/${campaignId}/overview`, this.requestConfig);
            return ret.status === 200;
        }
        catch (e) {
            handleError(e);
        }

        return false;
    }

    async skillsReports(campaignId: string, params?: IReportFilters): Promise<IReport[] | undefined> {
        let query = '';
        if (params) {
            query = objToQuery(params);
        }
        try {
            const ret = await Axios.get(`/campaign/${campaignId}/skills-reports${query}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }
    async skillsValues(campaignId: string, params?: IReportFilters): Promise<IReport[] | undefined> {
        let query = '';
        if (params) {
            query = objToQuery(params);
        }
        try {
            const ret = await Axios.get(`/campaign/${campaignId}/skills${query}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async requestPrediction(newCampaignId: string) {
        try {
            const ret = await Axios.get(`/campaigns/${newCampaignId}/prediction`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async deleteCampaign(campaignId: string) {
        try {
            await Axios.delete(`/campaigns/${campaignId}`, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }
}

import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ModalProvider from '../../providers/modalProvider';
import {Dialog, Transition} from '@headlessui/react';
import Button from '../Common/Button/Button';
import Input from '../Common/Input/Input';
import Dropzone from 'react-dropzone';
import FileService from 'metrics/services/http/File';
import Notification from 'metrics/components/Common/Notification/Notification';
import {uuidv4} from '../../utils/utils';
import {CheckCircleIcon} from '@heroicons/react/solid';
import { LocalLoader } from '../Common/Loader/Loader';
import {WIZARD_CREATE_PREDICTION_STEP_UPLOAD_FILE, WizardStorage} from '../../services/storage/Wizard';
import WizardCreatePredictionStepProvider from '../../providers/WizardCreatePredictionStepProvider';
import UserProvider from '../../providers/userProvider';
import PATHS from '../../../permissions/AppPaths';
import {useHistory} from 'react-router-dom';
import Select, {IValuesSelect} from '../Common/Select/Select';
import CompanyService from '../../services/http/Company';
import ErrorException from '../../services/exception/ErrorException';
import TPrediction, { TPredictionCreate } from 'metrics/types/TPrediction';
import Prediction from 'metrics/services/http/Prediction';
import {WIZARD_VIDEO_EXAMPLE, WIZARD_WALLPAPER_EXAMPLE} from '../../constants';
import FileUpload from '../Common/FileUpload/FileUpload';

interface IModalCreatePrediction {
    data: any
}
const ModalCreatePrediction = (props: IModalCreatePrediction) => {
    const { data } = props;
    const isWizardMode = typeof data === 'object' && 'wizardMode' in data && data.wizardMode;
    const [isOpen, setIsOpen] = useState(true);
    const [, setShowModal] = useContext(ModalProvider);
    const {t} = useTranslation();
    const [title, setTitle] = useState<string>('');
    const [files, setFiles] = useState<any[]>([]);
    const [sector, setSector] = useState<string>('');
    const [wallpaper, setWallpaper] = useState<string>('');
    const [sectors, setSectors] : any = useState([]);
    const [, setWizardStepCreatePrediction] = useContext(WizardCreatePredictionStepProvider);
    const wizard = new WizardStorage();
    const [user, setUser] = useContext(UserProvider);
    const history = useHistory();
    const closeModal = () => {
        setShowModal(false);
        setIsOpen(false);
    };

    const [isLoading, setIsLoading] = useState(false);

    const uploadVideo = async () => {
        if (files.length === 0) throw new Error('No file selected');
        const file = files[0];
        let mediaPath = '';
        const res = await new FileService().upload(file, 'media');
        mediaPath = res.data;
        return mediaPath;
    };

    const uploadWallpaper = async () => {
        if (wallpaper === '') return;
        let wallpaperPath = '';
        const res = await new FileService().upload(wallpaper, 'wallpaper');
        wallpaperPath = res.data.path;
        return wallpaperPath;
    };

    const updateUserPredictions = () => {
        const userCopy = {...user};
        userCopy.company.leftPredictions--;
        setUser(userCopy);
    };

    const valid = (): boolean => {
        return title !== '' && sector != '' && files.length > 0;
    };

    const onClickCreatePrediction = async () => {
        try {
            setIsLoading(true);
            const newPredictionId = uuidv4();
            if (isWizardMode && wizard.hasWizardNotStarted() && wizard.hasNotFinishedWizard()) {
                const prediction: TPrediction = {
                    id: data.id || uuidv4(),
                    title: data.title,
                    company: user.company,
                    sector: data.sector,
                    video: WIZARD_VIDEO_EXAMPLE,
                    media: WIZARD_VIDEO_EXAMPLE,
                    wallpaper: WIZARD_WALLPAPER_EXAMPLE,
                    completed: false,
                    createdAt: new Date(),
                    isFake: true,
                };
                wizard.saveWizardStepCreatePrediction(WIZARD_CREATE_PREDICTION_STEP_UPLOAD_FILE);
                wizard.savePredictionDemoData(prediction);
                setWizardStepCreatePrediction(WIZARD_CREATE_PREDICTION_STEP_UPLOAD_FILE);
            }
            else {
                const mediaPath = await uploadVideo();
                const wallpaperPath = await uploadWallpaper();
                const data: TPredictionCreate = {
                    media_path: mediaPath,
                    id: newPredictionId,
                    title: title,
                    sector: sector,
                    wallpaper: wallpaperPath,
                };
                await new Prediction().create(user.company.id, data);
                updateUserPredictions();
            }
            Notification.display('success', t('predictionRequestedSuccessfully'));
            history.push(PATHS.home);
        }
        catch (err) {
            Notification.displayException(err);
        }
        finally {
            setIsLoading(false);
            closeModal();
        }
    };

    const loadSectors = async () => {
        try {
            const res:IValuesSelect[] = await new CompanyService().sectorsForSelect();
            setSectors(res);
        }
        catch (err) {
            const error = new ErrorException(err.message);
            Notification.displayException(error);
        }
    };

    useEffect(() => {
        loadSectors();
        if (isWizardMode) {
            setWallpaper(WIZARD_WALLPAPER_EXAMPLE);
            setFiles([WIZARD_VIDEO_EXAMPLE]);
        }
    }, [data]);

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as={'div'} className="fixed inset-0 z-10 overflow-y-auto bg-ultra-dark-blue bg-opacity-80" onClose={() => {
                if (!isWizardMode) closeModal();
            }}>
                <div className="min-h-screen px-4 text-center flex items-center justify-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
            &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="w-full py-6 px-10 mb-40 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl md:w-1/2 flex flex-col space-y-6"
                        >
                            <Input name={'title'} label={t('title')} type={'text'} onChange={(value: string) => setTitle(value)} />
                            <Select
                                options={sectors}
                                label={t('sector')}
                                onChange={(value: string) => {
                                    setSector(value);
                                }}
                            />
                            { files.length <= 0 ? (
                                <Dropzone onDrop={(acceptedFiles) => setFiles(acceptedFiles)} accept={'video/*'}>
                                    {({getRootProps, getInputProps}) => (
                                        <section className="w-full border-dashed border-2 h-56 cursor-pointer">
                                            <div {...getRootProps()}
                                                className="flex flex-col items-center justify-center h-full space-y-3">
                                                <input {...getInputProps()} />
                                                <img src="/metricsAssets/upload_icon.png" alt="upload here icon"/>
                                                <p>
                                                    <span className="font-montserrat font-bold text-lightBlue-400">
                                                        {t('prediction.pickAFile')}&nbsp;
                                                    </span>
                                                    {t('prediction.orDragItHere')}
                                                </p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            ) : (
                                <section className="w-full border-dashed border-2 h-56 flex flex-col items-center justify-center space-y-3">
                                    <CheckCircleIcon className="w-32 h-32 text-green-500"/>
                                    <p>{files[0].path}</p>
                                </section>
                            )}
                            <FileUpload
                                type="image"
                                onFileSelected={(file) => {
                                    setWallpaper(file);
                                }}
                                helpText={t('predictionWallpaperHelp')}
                                uploadText={t('predictionWallpaperImageUploadText')}
                            />
                            <div className="flex flex-row justify-end space-x-3">
                                {isLoading? <LocalLoader /> :<button onClick={closeModal} className="font-montserrat text-sm" disabled={isLoading}>{t('prediction.cancel')}</button>}
                                <Button type={'primary'} disabled={!valid() || isLoading} onClick={onClickCreatePrediction} className="px-10">{t('prediction.request')}</Button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ModalCreatePrediction;

import React, {Fragment, useEffect, useState} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronDownIcon} from '@heroicons/react/solid';
import {useTranslation} from 'react-i18next';

export interface IValuesSelect {
  label: string;
  value: any;
}
export interface ISelectProps {
  color?: string;
  options: IValuesSelect[];
  onChange?(value: any): void;
  label?: string,
  defaultValue?: string,
}

const Select = (props: ISelectProps) => {
    const {color = 'primary', options, onChange, label, defaultValue} = props;
    const [selected, setSelected] = useState(options.length ? options[0] : null);
    const {t} = useTranslation();

    const _onChange = (value: any) => {
        setSelected(value);
        if (onChange && value) {
            onChange(value.value);
        }
    };

    const _loadDefault = () => {
        if (defaultValue) {
            for (let i = 0; i < options.length; i++) {
                const op = options[i];
                if (op.value == defaultValue) {
                    setSelected(op);
                    if (onChange) {
                        onChange(defaultValue);
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (defaultValue) {
            _loadDefault();
        }
        else {
            const v = options.length ? options[0] : null;
            _onChange(v);
        }
    }, [options, defaultValue]);

    return (
        <div className="relative">
            <div className="w-full">
                <Listbox value={selected} onChange={_onChange}>
                    <div className="relative mt-1">
                        <Listbox.Button className="relative w-full p-2 pl-4 text-left bg-white border focus-within:border-dark-grey-blue-2 rounded focus:outline-none">
                            <span className="block truncate">
                                {selected ? t(selected.label) : ''}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronDownIcon
                                    className="w-5 h-5 text-secondary"
                                    aria-hidden="true"
                                />
                            </span>

                        </Listbox.Button>
                        <label
                            className={`text-primary absolute -top-3 text-sm left-4 bg-mainBgColor duration-300 origin-0`}
                        >
                            {label}
                        </label>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="z-50 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {options.map((value, i) => (
                                    <Listbox.Option
                                        key={i}
                                        className={({active}) =>
                                            `${
                                                active ? `text-white bg-${color}` : 'text-gray-900'
                                            } cursor-pointer select-none relative py-2 pl-10 pr-4`
                                        }
                                        value={value}
                                    >
                                        {({selected, active}) => (
                                            <>
                                                <span
                                                    className={`${
                                                        selected ? 'font-medium' : 'font-normal'
                                                    } block truncate`}
                                                >
                                                    {t(value.label)}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`${
                                                            active ? `text-${color}-600` : `text-${color}-600`
                                                        } absolute inset-y-0 left-0 flex items-center pl-3`}
                                                    >
                                                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
        </div>
    );
};
export default Select;

import React, {useEffect} from 'react';
import mergeClasses from 'metrics/utils/TailwindMergeClasses';

export interface IInputProps {
    children?: any;
    inputClassName?: string;
    wrapperClassName?: string;
    placeholder?: string;
    label?: string;
    labelClassName?: string;
    type?: 'text' | 'password' | 'file' | 'date' | string;

    [key: string]: any;

    name: string;
    valid?: boolean;
    value?: any;
    invalidFeedback?: string;
    defaultValue?: string | any;
    disabled?: boolean;
    float?: boolean;
    icon?: JSX.Element;
}

const Input = (props: IInputProps) => {
    const {
        name = '',
        onChange,
        placeholder = ' ',
        label,
        labelClassName = '',
        type = 'text',
        inputClassName = '',
        wrapperClassName = '',
        invalidFeedback,
        valid,
        value,
        defaultValue,
        disabled,
        float = true,
        icon,
    } = props;

    const handleChange = (e: any) => {
        if (onChange) {
            const value = e.target.value;
            onChange(value);
        }
    };

    useEffect(() => {
        if (onChange) {
            onChange(defaultValue);
        }
    }, [defaultValue]);

    let inputClass = `block p-2 pl-4 w-full rounded appearance-none focus:outline-none bg-transparent ${inputClassName}`;
    let htmlInput: any;
    const disabledAttribute: any = {};
    if (disabled) {
        disabledAttribute['disabled'] = true;
        inputClass = mergeClasses('opacity-50', inputClass);
    }
    if (value != null) {
        htmlInput = (
            <input
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleChange}
                value={value}
                className={inputClass}
                {...disabledAttribute}
            />
        );
        if (type == 'textarea') {
            htmlInput = (
                <textarea
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    value={value}
                    className={inputClass}
                    {...disabledAttribute}
                />
            );
        }
    }
    else {
        htmlInput = (
            <input
                type={type}
                name={name}
                id={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={handleChange}
                className={inputClass}
                {...disabledAttribute}
            />
        );
        if (type == 'textarea') {
            htmlInput = (
                <textarea
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    className={inputClass}
                    {...disabledAttribute}
                />
            );
        }
    }

    const labelClass = mergeClasses('text-gray-500 absolute top-0 left-3 bg-mainBgColor p-2 duration-300 origin-0', labelClassName);
    const wrapperClass = mergeClasses(`${float ? 'floating-label' : ''} relative border focus-within:border-dark-grey-blue-2 rounded flex flex-row items-center`, wrapperClassName);
    return (
        <div className={`flex flex-col font-montserrat font-normal w-full ${disabled ? 'opacity-50' : ''}`} style={{letterSpacing: 0.14}}>
            <div className={` ${wrapperClass}`}>
                {htmlInput}
                {label && (
                    <label
                        htmlFor={name}
                        className={`${labelClass}`}
                    >
                        {label}
                    </label>
                )}
                {
                    icon
                }
            </div>
            {!valid && invalidFeedback ? (
                <span className="text-left pl-1 text-sm text-opacity-75 text-danger">
                    {invalidFeedback}
                </span>
            ) : (
                ''
            )}
        </div>
    );
};

export default Input;

// GLOBAL CONSTANTS
// -----------------------------------

import i18n from 'metrics/services/I18n';
import {TLanguage} from './types/TLanguage';

export const APP_RESOLUTIONS = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
};

export const OBJECTIVES_COLORS: any = {
    attention: '35, 204, 255',
    validation: '253, 62, 107',
    engagement: '255, 173, 94',
    charisma: '222, 121, 215',
    surprise: '90, 213, 187',
    inspire: '85, 122, 255',
    intensity: '167, 90, 246',
    reject: '255, 134, 110',
    horror: '175, 198, 104',
    upset: '253, 121, 159',
    scared: '135, 140, 233',
    wow: '255 ,0 ,0',
    angry: '255 ,0 ,0',
    remorse: '255 ,0 ,0',
    nostalgia: '255 ,0 ,0',
    disapproval: '255 ,0 ,0',
    intrigue: '255 ,0 ,0',
};

export const SERVER_ERRORS = {
    userNotVerified: 'user_not_verified',
};

export const APP_MODALS = {
    modalForgotPassword: 'modalForgotPassword',
    modalCancelSubscription: 'modalCancelSubscription',
    modalStartWizard: 'modalStartWizard',
    modalNewPrediction: 'modalNewPrediction',
    modalCheckEmailPassword: 'modalCheckEmailPassword',
    modalDeleteCampaign: 'modalDeleteCampaign',
    modalDeletePrediction: 'modalDeletePrediction',
    modalTutorials: 'modalTutorials',
};

export const GENDER_SELECT = [
    {label: 'male', value: 'male'},
    {label: 'female', value: 'female'},
    {label: 'other', value: 'other'},
];

export const LOCATION_SELECT = [
    {label: i18n('city'), value: 'city'},
    {label: i18n('province'), value: 'province'},
    {label: i18n('region'), value: 'region'},
    {label: i18n('country'), value: 'country'},
];

export const LANGUAGES:TLanguage[] = [
    {label: 'es', value: 'es'},
    {label: 'en', value: 'en'},
];

export const APP_AVATAR_FILE_MAX_SIZE = 3072;
export const APP_WALLPAPER_FILE_MAX_SIZE = 5120;
export const APP_MEDIA_FILE_MAX_SIZE = 30720;
export const CAMPAIGN_COLORS:TReportColor[] = [
    'pink',
    'purple',
    'blue',
    'turquoise',
    'green',
    'yellow',
    'orange',
    'red',
];
export const FEEDER_V1 = 'V1';
export const FEEDER_V2 = 'V2';
export type TReportColor = 'pink'| 'purple'| 'blue'| 'turquoise'| 'green'| 'yellow'| 'orange'| 'red';
export const DEFAULT_REPORT_COLOR:TReportColor = CAMPAIGN_COLORS[0];

export const DEFAULT_CONTENT_TYPE = 'application/json';
export const CSV_CONTENT_TYPE = 'text/csv';

export const WIZARD_VIDEO_EXAMPLE = 'https://static.getfeeder.com/assets/wizard/Don\'t+act%2C+react.mp4';
export const WIZARD_WALLPAPER_EXAMPLE = 'https://static.getfeeder.com/assets/wizard/Background+Image.jpg';

export const customLocaleSubdomains = [
    'wistron',
    'licencing-pre',
];

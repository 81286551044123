import TCampaign from '../../types/TCampaign';
import TPrediction from '../../types/TPrediction';

const WIZARD_CREATE_CAMPAIGN_STEP_NUMBER = 'wizard.create_campaign.step_number';
const WIZARD_CREATE_PREDICTION_STEP_NUMBER = 'wizard.create_prediction.step_number';
const WIZARD_FINISHED = 'wizard.finished';
const WIZARD_SHOWED = 'wizard.showed';
const WIZARD_CAMPAIGN_DEMO_DATA = 'wizard.campaign_demo_data';
const WIZARD_PREDICTION_DEMO_DATA = 'wizard.prediction_demo_data';
export const WIZARD_CREATE_CAMPAIGN_FINAL_STEP:number = 6;
export const WIZARD_CREATE_PREDICTION_FINAL_STEP:number = 3;
export const WIZARD_CREATE_PREDICTION_STEP_UPLOAD_FILE:number = 1;
export const WIZARD_STEP_FORM_SUBMITTED:number = 4;
export class WizardStorage {
    public hasFinishedWizard(): boolean {
        return localStorage.getItem(WIZARD_FINISHED) === 'true';
    }

    public saveWizardFinished(): void {
        return localStorage.setItem(WIZARD_FINISHED, 'true');
    }

    public refreshWizardFinished(): void {
        localStorage.removeItem(WIZARD_FINISHED);
    }

    public wizardStepCreateCampaign(): number {
        return Number(localStorage.getItem(WIZARD_CREATE_CAMPAIGN_STEP_NUMBER));
    }

    public wizardCreateCampaignCanMakeTourCampaignReport(): boolean {
        return this.hasNotFinishedWizard() && (this.wizardStepCreateCampaign() === 5 || this.wizardStepCreatePrediction() === 2);
    }

    public wizardStepCreatePrediction(): number {
        return Number(localStorage.getItem(WIZARD_CREATE_PREDICTION_STEP_NUMBER));
    }

    public saveWizardStepCreateCampaign(step: number): void {
        localStorage.setItem(WIZARD_CREATE_CAMPAIGN_STEP_NUMBER, step.toString());
    }

    public saveWizardStepCreatePrediction(step: number): void {
        localStorage.setItem(WIZARD_CREATE_PREDICTION_STEP_NUMBER, step.toString());
    }

    public hasNotFinishedWizard(): boolean {
        return !this.hasFinishedWizard();
    }

    public refreshStepsDone() {
        localStorage.removeItem(WIZARD_CREATE_CAMPAIGN_STEP_NUMBER);
        localStorage.removeItem(WIZARD_CREATE_PREDICTION_STEP_NUMBER);
    }

    public hasWizardStarted(): boolean {
        return this.hasWizardCreateCampaignStarted() || this.hasWizardCreatePredictionStarted();
    }

    public hasWizardCreateCampaignStarted(): boolean {
        return Number(localStorage.getItem(WIZARD_CREATE_CAMPAIGN_STEP_NUMBER)) > 0;
    }

    public hasWizardCreatePredictionStarted(): boolean {
        return Number(localStorage.getItem(WIZARD_CREATE_PREDICTION_STEP_NUMBER)) > 0;
    }

    public hasWizardNotStarted(): boolean {
        return !this.hasWizardStarted();
    }
    public hasWizardShowed(): boolean {
        const showed = localStorage.getItem(WIZARD_SHOWED);
        if (showed === null) {
            return false;
        }
        return Boolean(showed);
    }

    public saveWizardShowed(): void {
        localStorage.setItem(WIZARD_SHOWED, 'true');
    }

    public hasWizardNotShowed(): boolean {
        return !this.hasWizardShowed();
    }

    public refreshWizardShowed(): void {
        localStorage.removeItem(WIZARD_SHOWED);
    }

    public refreshAll(): void {
        this.refreshWizardShowed();
        this.refreshStepsDone();
        this.refreshWizardFinished();
        this.refreshCampaignDemo();
        this.refreshPredictionDemo();
    }

    public saveCampaignDemo(
        campaign: TCampaign
    ): void {
        const data = JSON.stringify(campaign);
        localStorage.setItem(WIZARD_CAMPAIGN_DEMO_DATA, data);
    }

    public campaignDemo(): TCampaign | null {
        const data = localStorage.getItem(WIZARD_CAMPAIGN_DEMO_DATA);
        if (data) {
            return JSON.parse(data) as TCampaign;
        }
        return null;
    }

    public refreshCampaignDemo(): void {
        localStorage.removeItem(WIZARD_CAMPAIGN_DEMO_DATA);
    }

    public savePredictionDemoData(prediction: TPrediction) {
        const data = JSON.stringify(prediction);
        localStorage.setItem(WIZARD_PREDICTION_DEMO_DATA, data);
    }

    public predictionDemoData(): TPrediction|null {
        const data = localStorage.getItem(WIZARD_PREDICTION_DEMO_DATA);
        if (data) {
            return JSON.parse(data) as TPrediction;
        }
        return null;
    }

    public refreshPredictionDemo(): void {
        localStorage.removeItem(WIZARD_PREDICTION_DEMO_DATA);
    }
}

import Axios from 'axios';
import Http from './Http';
import {handleError} from './utils';
import {
    APP_AVATAR_FILE_MAX_SIZE,
    APP_WALLPAPER_FILE_MAX_SIZE,
    APP_MEDIA_FILE_MAX_SIZE,
} from '../../constants';

import i18n from '../../i18n';
import ErrorException from 'metrics/services/exception/ErrorException';

export default class File extends Http {
    constructor() {
        super('');
    }

    async upload(file: any, type: 'avatar' | 'wallpaper' | 'media' | 'thumb' | 'alternative') {
        let result: any = {data: ''};

        const fileSize = Math.round(file.size / 1024);
        let maxFileSize = APP_AVATAR_FILE_MAX_SIZE;
        if (type == 'wallpaper' || type == 'thumb') {
            maxFileSize = APP_WALLPAPER_FILE_MAX_SIZE;
        }
        else if (type == 'media' || type == 'alternative') {
            maxFileSize = APP_MEDIA_FILE_MAX_SIZE;
        }

        // if (fileSize > maxFileSize) {
        //     throw new ErrorException(i18n.t('errors.fileToBig'));
        // }

        try {
            const form = new FormData();
            form.append('type', type);
            form.append(type === 'media' ? 'file' : 'media', file);

            result = type === 'media' ?
                await Axios.post('/assets/files', form, this.requestConfig) :
                await Axios.post('/files', form, this.requestConfig);
        }
        catch (err) {
            console.log(err);
            handleError(err);
        }
        return result;
    }
}

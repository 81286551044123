import Axios from 'axios';
import {objToQuery} from 'metrics/utils/Router';
import Http from './Http';
import {handleError} from './utils';
import {TContentType} from '../../types/TContentType';
import {DEFAULT_CONTENT_TYPE} from '../../constants';
import TPrediction, { TPredictionCreate, TPredictionFilters } from 'metrics/types/TPrediction';

export default class Prediction extends Http {
    constructor() {
        super('');
    }

    async list(companyId: string): Promise<TPrediction[]> {
        let result = {data: []};

        try {
            result = await Axios.get(
                `company/${companyId}/predictions`,
                this.requestConfig
            );
        }
        catch (err) {
            handleError(err);
        }
        return result.data;
    }

    async create(companyId: string, request: TPredictionCreate) {
        let result = {data: []};
        try {
            result = await this.post(`/company/${companyId}/predictions`,
                request,
                this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }

        return result;
    }

    async get(predictionId: string): Promise<TPrediction | undefined> {
        try {
            const ret = await Axios.get(`/predictions/${predictionId}`, this.requestConfig);
            if (ret) {
                return ret.data;
            }
        }
        catch (err) {
            handleError(err);
        }
    }

    async getOverviewReport(predictionId: string): Promise<any> {
        this.requestConfig.headers = { Version: 2 };
        try {
            const ret = await Axios.get(`/prediction/${predictionId}/overview`, this.requestConfig);
            return ret.data;
        }
        catch (e) {
            handleError(e);
        }
    }

    async getAnalysisReport(predictionId: string, params?: TPredictionFilters, contentType?: TContentType): Promise<any> {
        if (contentType === undefined || contentType === null) {
            contentType = DEFAULT_CONTENT_TYPE;
        }
        this.requestConfig.headers = {
            'Version': 2,
            'Accept': contentType,
        };


        let query = '';
        if (params) {
            query = objToQuery(params);
        }
        try {
            const ret = await Axios.get(`/prediction/${predictionId}/analysis${query}`, this.requestConfig);
            return ret.data;
        }
        catch (e) {
            handleError(e);
        }
    }

    async isComplete(predictionId: string): Promise<boolean> {
        try {
            const ret = await Axios.head(`/prediction/${predictionId}/overview`, this.requestConfig);
            return ret.status === 200;
        }
        catch (e) {
            handleError(e);
        }

        return false;
    }
    async delete(predictionId: string) {
        try {
            await Axios.delete(`/predictions/${predictionId}`, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }
}

import { ESocketEventsOn } from 'metrics/types/ESocketEvents';
import React, { createContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

interface ISocketConnectionContext {
    socket: Socket | null;
    token: string | null;
    setToken: (token: string | null) => void;
}
export const SocketConnectionContext = createContext<ISocketConnectionContext>({
    socket: null,
    token: null,
    setToken: () => {},
});

export const SocketConnectionProvider: React.FC<{ children: React.ReactNode }> =
    ({ children }) => {
        const [socket, setSocket] = useState<Socket | null>(null);
        const [token, setToken] = useState<string | null>(
            localStorage.getItem('auth.accessToken')
        );

        useEffect(() => {
            const createSocket = (token: string | null) => {
                const newSocket = io(SOCKET_URL, {
                    path: '/assets/socket.io',
                    transports: ['websocket'],
                    timeout: 20000,
                    auth: { token },
                });

                newSocket.on(ESocketEventsOn.CONNECT, () => {
                    console.log('Connected to WebSocket server');
                });

                newSocket.on(ESocketEventsOn.DISCONNECT, (reason: string) => {
                    console.log('Disconnected from WebSocket server:', reason);
                    setTimeout(() => {
                        newSocket.connect();
                    }, 5000);
                });

                newSocket.on(ESocketEventsOn.CONNECT_ERROR, (err: Error) => {
                    console.error('Connection Error:', err);
                });

                setSocket(newSocket);
            };

            createSocket(token);

            return () => {
                socket?.disconnect();
            };
        }, [token]);

        useEffect(() => {
            const handleStorageChange = () => {
                const newToken = localStorage.getItem('auth.accessToken');
                if (newToken !== token) {
                    setToken(newToken);
                }
            };

            window.addEventListener('storage', handleStorageChange);

            return () => {
                window.removeEventListener('storage', handleStorageChange);
            };
        }, [token]);

        return (
            <SocketConnectionContext.Provider value={{ socket, setToken, token }}>
                {children}
            </SocketConnectionContext.Provider>
        );
    };

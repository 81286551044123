import React, {lazy} from 'react';
import EUserRol from 'metrics/types/EUserRol';

interface IRoute {
  path: string;
  component: React.Component | any;
  roles: EUserRol[];
}

const Welcome = lazy(() => import('pages/Welcome/Welcome'));
const Home = lazy(() => import('pages/Home/Home'));
const SignInForm = lazy(() => import('pages/SignIn/SignIn'));
const LoginVerification = lazy(
    () => import('pages/LoginVerification/LoginVerification')
);
const NewPassword = lazy(() => import('pages/NewPassword/NewPassword'));
const SignUp = lazy(() => import('pages/SignUp/SignUp'));
const NewCompany = lazy(() => import('pages/NewCompany/NewCompany'));
const Invite = lazy(() => import('pages/Invite/Invite'));
const Subscription = lazy(() => import('pages/Subscription/Subscription'));
const BuyPackages = lazy(() => import('pages/BuyPackages/BuyPackages'));
const Profile = lazy(() => import('pages/Profile/Profile'));
const CampaignForm = lazy(() => import('pages/CampaignForm/CampaignFormWrapper'));
const Campaign = lazy(() => import('pages/Campaign/CampaignWrapper'));
const SocialSignUpForm = lazy(() => import('pages/SignUp/SignUpSocial'));
const Prediction = lazy(() => import('pages/Prediction/PredictionWrapper'));
const DemoCampaign = lazy(() => import('pages/Campaign/DemoCampaignWrapper'));
const DemoPrediction = lazy(() => import('pages/Prediction/DemoPredictionWrapper'));

export const PATHS = {
    login: '/login',
    loginVerification: '/verification',
    socialSingUpComplementaryForm: '/signupSocial',
    signup: '/signup',

    welcome: '/welcome',
    home: '/home',
    newCompany: '/company/new',
    companyInvite: '/company/invite',
    companySubscription: '/company/subscription',
    buyPackages: '/packages',
    profile: '/profile',

    newCampaign: '/campaign/new',
    campaign: '/campaign/:id',
    prediction: '/prediction/:id',
    editCampaign: '/campaign/:id/edit',
    duplicateCampaign: '/campaign/:id/duplicate',
    demoCampaign: '/campaign/demo',
    demoPrediction: '/prediction/demo',

    renewPassword: '/newPassword',

    config: '/config',
    componentTest: '/components',
};
export default PATHS;

export const AUTH_ROUTES: IRoute[] = [
    {
        path: PATHS.demoCampaign,
        component: DemoCampaign,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.demoPrediction,
        component: DemoPrediction,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.prediction,
        component: Prediction,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.welcome,
        component: Welcome,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.home,
        component: Home,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.companyInvite,
        component: Invite,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.companySubscription,
        component: Subscription,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.buyPackages,
        component: BuyPackages,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.newCompany,
        component: NewCompany,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.profile,
        component: Profile,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.newCampaign,
        component: CampaignForm,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.campaign,
        component: Campaign,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.editCampaign,
        component: CampaignForm,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.duplicateCampaign,
        component: CampaignForm,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.renewPassword,
        component: NewPassword,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.socialSingUpComplementaryForm,
        component: SocialSignUpForm,
        roles: [EUserRol.ADMIN],
    },
];

export const ROUTES: IRoute[] = [
    {
        path: PATHS.login,
        component: SignInForm,
        roles: [],
    },
    {
        path: PATHS.loginVerification,
        component: LoginVerification,
        roles: [],
    },
    {
        path: PATHS.signup,
        component: SignUp,
        roles: [],
    },
];

export function getRoutesByRole(roles: EUserRol[]): IRoute[] {
    const ret: IRoute[] = [];

    AUTH_ROUTES.forEach((route: IRoute) => {
        let i = 0;
        let hasRole = false;
        while (!hasRole && i < roles.length) {
            hasRole = route.roles.indexOf(roles[i]) !== -1;
            i++;
        }
        if (hasRole) {
            ret.push(route);
        }
    });

    return ret;
}

export function getUnauthorizedRoutes(): IRoute[] {
    return ROUTES;
}

/* eslint-disable no-unused-vars */
export enum ESocketEventsEmit {
    MULTIPART_UPLOADER = 'multipart-uploader',
};

export enum ESocketEventsOn {
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
    CONNECT_ERROR = 'connect_error',
    ERROR = 'error',
    MULTIPART_UPLOADER_INIZIALIZED = 'init-upload-multipart',
    MULTIPART_UPLOADER_UPLOAD_PART = 'etag',
    MULTIPART_UPLOADER_COMPLETED = 'url',
};
